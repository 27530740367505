<template>
    <div>
        <!-- <div class="banner"><img src="../assets/images/banner/lxwm.png" alt=""></div> -->
        <!-- <div class="banner-wrap">            
            <div class="swiper-container" ref="banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in myList.we_lbs" :key="index"  @click="goUrl(item.link)">
                        <img :src="item.pic_url" alt="">
                    </div>
                </div>
                <div class="swiper-pagination" ref="swiper-pagination"></div>
            </div>
        </div> -->
        <banner></banner>
        <div class="ptjs-wrap">
            <div class="ptjs-top w-1300">
                <div class="center-text">
                    <p></p>
                    <span>联系我们</span>
                </div>
                <div class="ditu">
                    <div class="ditu-left">
                        <div class="map" id="map"></div>
                    </div>
                    <div class="ditu-right">
                        <div class="ditu-text">{{myList.title}}</div>
                        <div class="ditu-tu">
                            <img src="../assets/images/lxwm-phone.png" alt="">
                        </div>   
                        <template v-for="(item,index) in myList.lxs">
                            <div  :key="index">
                                <div class="ditu-p" >联系人：{{item.full}}</div>                   
                                <div class="ditu-p phone">电话：{{item.phone}}</div>
                            </div>                            
                        </template>  
                        
                        <!-- <div class="ditu-p">联系人：崔先生</div>
                        <div class="ditu-p phone">电话：15838123662</div> -->
                        <div class="ditu-tu">
                            <img src="../assets/images/lxwm-loca.png" alt="">
                            <p>地址：{{myList.address}}</p>
                        </div>

                    </div>

                </div>
            </div>            
        </div>
        <div class="liuyan  w-1300">
            <div class="center-text">
                <p></p>
                <span>在线留言</span>
            </div>
            <div class="liuyan-from">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <div class="liuyan-item">
                        <el-form-item label="姓名:" prop="full">
                            <el-input v-model="ruleForm.full"  placeholder="请写您的姓名"></el-input>
                        </el-form-item>  
                        <el-form-item label="公司:" prop="company">
                            <el-input v-model="ruleForm.company" placeholder="请填写您的公司"></el-input>
                        </el-form-item>                  
                        <!-- <el-form-item label="性别:">
                            <el-radio-group v-model="ruleForm.sex">
                            <el-radio label="男"></el-radio>
                            <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>                         -->
                    </div>
                    <div class="liuyan-item">
                        <el-form-item label="联系电话:" prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="请写您的联系电话"></el-input>
                        </el-form-item> 
                        <el-form-item label="电子邮件:" prop="email">
                            <el-input v-model="ruleForm.email" placeholder="请填写您的邮箱"></el-input>
                        </el-form-item> 
                    </div>                    
                    <el-form-item label="留言:" prop="content">
                        <el-input type="textarea" v-model="ruleForm.content"></el-input>
                    </el-form-item>
                    <div class="liuyan-item hzy">
                        <el-form-item label="验证码:">
                            <el-input v-model="mycode" placeholder="请填写验证码"></el-input>
                        </el-form-item>
                        <div class="yzmcode">{{code}}</div>
                    </div>  
                    <div class="tijiao">
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </div>
                    
                </el-form>
            </div>
        </div>
        <div class="lxwm-bottom">
            <img src="../assets/images/lxwm-bottom.png" alt="">
        </div>
        

    </div>
</template>

<script>
import banner from '../components/banner.vue';
    import Swiper from '../assets/js/swiper'
    import {getwes, mess} from '../api/index'
    import {mapState, mapMutations, mapActions} from 'vuex'  
    export default {
        name:'contact_us',
        components:{
            banner
        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请填写您的联系电话'));
                } else {
                    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                    // console.log(reg.test(value));
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                myList:[],
                address:'',
                loca:'',
                ruleForm: {
                    full: '',
                    company:'',
                    // sex: [],
                    phone: '',
                    email: '',
                    content:''
                },
                code:'',
                mycode:'',
                rules: {
                    full: [
                        { required: true, message: '请填写您的姓名', trigger: ['blur', 'change'] },
                    ],
                    company:[
                        { required: true, message: '请填写您的公司', trigger: ['blur', 'change'] },
                    ],
                    phone: [
                        {required: true,validator: checkPhone, trigger: ['blur', 'change']}
                    ],
                    email: [
                        { required: true, message: '请填写您的邮箱', trigger: ['blur', 'change'] },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    content: [
                        { required: true, message: '请填写留言', trigger: ['blur', 'change'] },
                    ],
                }
            }
        },  
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.getwes()
            this.createCode()            
        },
        mounted() {
                       
        },  
        methods: {
            goUrl(url){
                if (url) {
                    window.open(url)                     
                }else{
                    return false;
                }
            },
            map(){
                setTimeout(() => {
                    // console.log(this.loca[0])
                    var x = this.loca[0];
                    var y =this.loca[1]
                    var map = new BMapGL.Map('map');
                    map.centerAndZoom(new BMapGL.Point(x,y), 15);
                    map.enableScrollWheelZoom(true);
                    // 创建点标记
                    var marker1 = new BMapGL.Marker(new BMapGL.Point(x,y));
                    // 在地图上添加点标记
                    map.addOverlay(marker1);
                }, 500); 
            },
            
            getwes(){
                getwes().then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.myList=res.data
                        this.loca = res.data.map.split(",");
                        this.map()
                        // this.$nextTick(()=>{
                        //     let mySwiper = new Swiper(this.$refs['banner'], {
                        //         autoplay: 1000,
                        //         autoplayDisableOnInteraction : false,
                        //         loop:true,
                        //         pagination : this.$refs['swiper-pagination'],
                        //         paginationClickable :true,
                        //     })
                        //     this.$refs['banner'].onmouseenter = function () {
                        //         mySwiper.stopAutoplay();
                        //     };
                        //     this.$refs['banner'].onmouseleave = function () {
                        //         mySwiper.startAutoplay();
                        //     }
                        // });
                    }
                })
            },
            createCode(){
                let code='';
                var codeLength = 4;//验证码的长度
                let  selectChar = new Array(0,1,2,3,4,5,6,7,8,9,'a','b','c','d','e','f','g','h','i','j','k',
                        'l','m','n','o','p','q','r','s','t','u','v','w','x','y','z');//所有候选组成验证码的字符
                        
                for(let i=0;i<codeLength;i++)
                {     
                    let charIndex =Math.floor(Math.random()*36);
                    code +=selectChar[charIndex];     
                }
                this.code = code;
                // console.log(code)
            },
            submitForm(formName) {
                // if ( !this.isLogin) {
                //     this.$router.push({
                //         path:'/login'
                //     })
                //     return
                // }
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log(this.ruleForm)
                   if (this.mycode.trim()=='') {
                        this.$message.error("请输入验证码")
                        return;
                    }
                    if (this.mycode!=this.code) {
                        this.$message.error("验证码错误")
                        return;
                    }
                    mess(this.ruleForm).then((res)=>{
                        console.log(res);
                        if (res.code==200) {
                            this.$message.success(res.message)
                            this.createCode()
                            this.mycode=''
                            this.$refs[formName].resetFields();
                        }
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
                });
            },
            // 重置
            resetForm(formName) {
                this.createCode()
                this.mycode=''
                this.$refs[formName].resetFields();
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
@import "../assets/css/swiper.css" ;
    // 轮播
    .banner-wrap{width: 100%;height: 400px;}
    .banner-wrap .swiper-container{width: 100%;height: 100%;}
    .banner-wrap .swiper-slide{
        width: 100%;height: 100%;
        img{width: 100%;height: 100%;object-fit: cover;}
    }
    /deep/ .banner-wrap .swiper-pagination-bullet{background: #fff;opacity: .5;}
    /deep/ .banner-wrap .swiper-pagination-bullet-active{width: 20px;border-radius: 30px;background: #fff;opacity: 1;}
    .center-text{
        display: flex;align-items: center;margin-top:45px;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 20px;color: #000000;margin-left: 10px;}
    }
    .ptjs-wrap{
        background: #F2F2F3;overflow: hidden;
        .ptjs-top{margin: auto;}
        .ditu{
            width:1500px;margin: 80px auto 110px;display: flex;justify-content: center;
            .ditu-left{
                width: 60%;height: 450px;background: #fff;padding: 12px 11px;box-sizing: border-box;
                .map{width: 100%;height: 100%;}
            }
            .ditu-right{flex:1;position: relative;
                background: #0071DB;padding: 55px 30px;box-sizing: border-box;color:#fff;
                .ditu-text{font-size: 23px;margin-bottom: 30px;}
                .ditu-tu{
                    display: flex;align-items: center;position: absolute;
                    img{width: 26px;}
                    p{font-size: 16px;margin-left: 15px;}
                }
                .ditu-p{margin-left: 41px;font-size: 16px;}
                .phone{margin-bottom: 27px;margin-top: 10px;}
            }

        }

    }

    .liuyan{
        margin: auto;overflow: hidden;
        .liuyan-from{
            width: 1400px;margin: auto;margin-top:60px;
            .liuyan-item{
                display: flex;justify-content: space-between;
                /deep/ .el-form-item{width: 40%;}
            }
            /deep/ .el-form-item__label , /deep/ .el-radio__label{font-size: 16px;color: #4D4C4C;}
            /deep/ .el-input__inner{height: 45px;font-size: 16px;}
            /deep/ .el-textarea__inner{height: 200px;resize: none;font-size: 16px;}
            .yzmcode{width: 105px;height: 45px;background: #e6e6e6;margin-left: 10px;font-size: 22px; letter-spacing: 8px;line-height: 45px;text-align: center;}
            .hzy{justify-content: flex-start;}
            .tijiao{margin-top: 23px;
                // /deep/ .el-form-item__content{margin-left: 0 !important;}
            }
            /deep/ .el-button{width: 146px; height: 56px;font-size: 18px;}
        }
    }
    .lxwm-bottom{
        width: 100%;margin-top:50px;
        img{width: 100%;display: flex;}
    }
    

</style>